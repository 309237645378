$(document).ready(function () {
  var navOffset = 0;

  function updateHeaderElevation() {
    const headerOffset = $("#logo-bar").css("top");
    const scrollTop = document.scrollingElement.scrollTop;

    $("#logo-bar").addClass("initialized")

    if (scrollTop > 15) {
      $("#logo-bar").addClass("elevated")
    } else {
      $("#logo-bar").removeClass("elevated")
    }

    if (scrollTop > 100) {
      $("#page-header").css("transform", `translateY(-${headerOffset})`);
      navOffset = parseFloat(headerOffset);
      repositionHeader();
    } else {
      $("#page-header").css("transform", "translateY(0)");
      navOffset = 0;
      repositionHeader();
    }
  }

  function repositionHeader() {
    let height = 0;
    $("#page-header-parent")
      .children()
      .each(function () {
        const navMenu = this.querySelector("#nav-menu");

        this.style.top = `${height}px`;
        if (navMenu) {
          navMenu.style.top = `${height}px`;
          navMenu.style.maxHeight = `calc(100vh - ${height - navOffset}px)`;
        }

        height += $(this).outerHeight();
      });
  }

  function initDesktopMenu() {
    $("#nav-menu-list .nav-submenu").hover(
      function () {
        $(this).data("timeout", setTimeout(() => {
          $(this).children(".collapse").collapse("show");
          $(this).siblings(".nav-submenu").children(".collapse").collapse("hide");
        }, 300));
      },
      function () {
        const timeout = $(this).data("timeout");
        if (timeout) clearTimeout(timeout);

        $(this).children(".collapse").collapse("hide");
      }
    );
  }

  $('#search-bar-collapse').on('shown.bs.collapse', function() {
    this.querySelector('input').focus();
  });

  document.addEventListener("scroll", updateHeaderElevation);

  const observer = new ResizeObserver(repositionHeader);
  $("#page-header-parent")
    .children()
    .each(function () {
      observer.observe(this);
    });

  // Only apply hover menu behavior on desktop
  if (!window.matchMedia || !window.matchMedia("(pointer: coarse)").matches) {
    initDesktopMenu();
  }
});
